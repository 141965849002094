import React, { useEffect } from "react";
import _get from "lodash.get";
import { navigate } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

import Blog from "../templates/Blog";

const BlogIndex = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(templateId: { eq: "blog" }) {
        ...Blog
      }
      blog: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/.*.md$/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        posts: nodes {
          file: parent {
            ... on File {
              id
              name
            }
          }
          ...BlogPostCard
        }
      }
    }
  `);

  const posts = _get(data, "blog.posts", []);

  useEffect(() => {
    if (!Array.isArray(posts) || !posts.length) {
      navigate("/404/");
    }
  }, [posts]);

  return (
    <Blog
      {..._get(data, "contentJson", {})}
      posts={_get(data, "blog.posts", [])}
    />
  );
};

export default BlogIndex;
