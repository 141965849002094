import React from "react";
import _get from "lodash.get";

import BlogPostCard from "./blog-post-card";

import * as S from "./blog-post-list.styles";

const BlogPostList = ({ posts }) => {
  if (!Array.isArray(posts) || !posts.length) {
    return null;
  }

  return (
    <div css={[S.container, S.grid, S.pb72]}>
      {posts.map(({ frontmatter, ...post }) => (
        <BlogPostCard
          {...frontmatter}
          {...post}
          uri={`/blog/${_get(post, "file.name")}`}
        />
      ))}
    </div>
  );
};

export default BlogPostList;
