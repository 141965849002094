import { css } from "@emotion/react";

export { container, pb72 } from "../styles/shared";

export const grid = css`
  display: grid;
  grid-row-gap: 90px;
  grid-column-gap: 60px;
  grid-template-columns: 1fr;

  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }
`;
