import React from "react";
import { graphql } from "gatsby";

import Link from "./link";
import Image from "./image";
import MarkdownBlock from "./markdown-block";

import * as S from "./blog-post-card.styles";

const BlogPostCard = ({ title, uri, image, tag, excerpt, previewText }) => (
  <article>
    <Link css={S.mb6} to={uri} aria-hidden="true" variant={"none"}>
      <Image image={image} />
    </Link>
    <header css={S.flexColRev}>
      <h1 css={[S.mb1]}>
        <Link
          to={uri}
          aria-hidden="true"
          css={[S.bold, S.textXl]}
          variant={"none"}
        >
          {title}
        </Link>
      </h1>

      <p css={[S.bold, S.mb1, S.uppercase]}>{tag}</p>
    </header>
    <div css={S.textLg}>
      <MarkdownBlock content={previewText || excerpt} />
    </div>
  </article>
);

export default BlogPostCard;

export const BlogPostCardFragment = graphql`
  fragment BlogPostCard on MarkdownRemark {
    frontmatter {
      title
      tag
      image {
        childImageSharp {
          gatsbyImageData(
            width: 500
            # placeholder: TRACED_SVG
            aspectRatio: 1.77
            formats: [AUTO, WEBP]
          )
        }
      }
      previewText
    }
    excerpt
  }
`;
