import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";
import { css } from "@emotion/react";

import Layout from "../components/layout";
import BlogPostList from "../components/blog-post-list";
import SEOBlock, { seoFields } from "../components/seo";
import HeroSimple from "../components/hero-simple";

import * as S from "../styles/shared";

const Blog = (data) => (
  <Layout css={[S.containerNarrow, S.bgOffWhite]}>
    <SEOBlock {..._get(data, "seo")} titleTemplate="%s | TED@Work" />
    <HeroSimple
      title={_get(data, "title")}
      intro={_get(data, "intro")}
      hasBorder={false}
      css={css`
        max-width: 480px;
      `}
    />
    <BlogPostList posts={_get(data, "posts", [])} />
  </Layout>
);

Blog.defaultProps = {
  title: "Latest articles",
};

export const blogFields = [
  seoFields,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "string",
  },
];

export const BlogFragment = graphql`
  fragment Blog on ContentJson {
    title
    intro
    seo {
      ...SEO
    }
  }
`;

export default Blog;
